import React, { useEffect } from "react";
import "./Products.css";

//* Imported images
import pro1 from "../../images/1.jpg";
import pro2 from "../../images/2.jpg";
import pro3 from "../../images/3.jpg";
import pro4 from "../../images/4.jpg";
import pro5 from "../../images/5.jpg";
import pro6 from "../../images/6.jpg";

//* react-icons
import { TbBrandShopee } from "react-icons/tb";
import { Link } from "react-router-dom";
import { MdOutlinePhone } from "react-icons/md";

// Aos
import Aos from "aos";
import "aos/dist/aos.css";

//* Our Products api
const products = [
  {
    id: 1,
    img: pro1,
    brand: "Stellar",
    productsName: "Solar Heater 300L Non-Pressurized",
    price: 0,
    discount: 0,
    iconShope: MdOutlinePhone,
    btn: "WhatsApp",
  },
  {
    id: 2,
    img: pro2,
    brand: "Stellar",
    productsName: "Solar Heater 300L Non-Pressurized",
    price: 0,
    discount: 0,
    iconShope: MdOutlinePhone,
    btn: "WhatsApp",
  },
  {
    id: 3,
    img: pro3,
    brand: "Stellar",
    productsName: "Solar Heater 300L Non-Pressurized",
    price: 0,
    discount: 0,
    iconShope: MdOutlinePhone,
    btn: "WhatsApp",
  },
  {
    id: 4,
    img: pro4,
    brand: "Stellar",
    productsName: "Solar Heater 300L Non-Pressurized",
    price: 0,
    discount: 0,
    iconShope: MdOutlinePhone,
    btn: "WhatsApp",
  },
  {
    id: 5,
    img: pro5,
    brand: "Stellar",
    productsName: "Solar Heater 300L Non-Pressurized",
    price: 0,
    discount: 0,
    iconShope: MdOutlinePhone,
    btn: "WhatsApp",
  },
  {
    id: 6,
    img: pro6,
    brand: "Stellar",
    productsName: "Solar Heater 300L Non-Pressurized",
    price: 0,
    discount: 0,
    iconShope: MdOutlinePhone,
    btn: "WhatsApp",
  },
];

const Products = () => {
  useEffect(() => {
    Aos.init({
      duration: 1300,
    });
  });
  return (
    <>
      <div class="text-center p-10">
        <h1 class="font-bold text-4xl mb-4" data-aos="fade-up">
          Our Products Stellar
        </h1>
        <h5 class="text-xl" data-aos="fade-up">
          When you purchase a solar water heater from Stellar, you will receive
          a 5-years warranty on the entire product.
        </h5>
      </div>

      <section
        id="Projects"
        class="w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-10 mb-5">
        {products.map((data, i) => {
          return (
            <div
              data-aos="fade-up"
              key={i}
              class="w-72 bg-white shadow-md rounded-xl duration-500 hover:shadow-xl">
              <div>
                <img
                  src={data.img}
                  alt="Product"
                  class="h-80 w-72 transition-all hover:scale-105 object-cover rounded-t-xl bg-cover bg-no-repeat bg-center"
                />
                <div class="px-4 py-3 w-72">
                  <span class="text-[--primary-color] mr-3 uppercase text-md font-medium">
                    {data.brand}
                  </span>
                  <p class="text-lg font-bold text-black  block capitalize">
                    {data.productsName}
                  </p>
                  <div class="flex items-center">
                    <p class="text-lg font-semibold text-black cursor-auto my-3">
                      ${data.discount}
                    </p>
                    <del>
                      <p class="text-sm text-gray-600 cursor-auto ml-2">
                        ${data.price}
                      </p>
                    </del>
                    <div class="ml-auto flex items-center">
                      <a
                        href="https://wa.me/+201552900437"
                        className="button mr-5 w-32 h-fit text-center text-gray-100 flex justify-center items-center">
                        <h3 title="contactUs"> {data.btn}</h3>
                      </a>
                      <a href="tel:+201014790046">
                        {<data.iconShope className="icon text-3xl" />}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
};

export default Products;
