import React, { useEffect } from "react";

//* Imported Images
import dataSheet from "./images/dataSheet.png";
import detailsOfProduct from "./images/detailsOfProducts.jpg";
import controller from "./images/Controller.png";
// Aos
import Aos from "aos";
import "aos/dist/aos.css";

//* DataSheet
const dataSheetContent = {
  img: dataSheet,
  brand: "STELLAR",
  title: "Data Sheet Of our Products",
  description:
    "For more details, please contact us via social media platforms or WhatsApp.",
  button: "Download Data Sheet",
  downloadUrl: "./SWH_datasheet[1].pdf",
};

//* DataSheet
const detailsOfProductContent = {
  img: detailsOfProduct,
  brand: "STELLAR",
  title: "More Details Of our Products",
  description:
    "For more details, please contact us via social media platforms or WhatsApp.",
  button: "Download Details Of Products",
  downloadUrl: "./SWH_datasheet[1].pdf",
};

//* DataSheet
const controllerContent = {
  img: controller,
  brand: "STELLAR",
  title: "Control Digital",
  description:
    "For more details, please contact us via social media platforms or WhatsApp.",
  button: "Download Control Data",
  downloadUrl: "./M-8-user-manual[1].pdf",
};


const DataSheet = () => {
  useEffect(() => {
    Aos.init({
      duration: 1300,
    })
  }, [])
  return (
    <section className="p-4 lg:p-8 dark:bg-gray-100 dark:text-gray-800">
      <div className="titleSection text-center mb-10 md:mb-10 px-4 md:px-8 lg:px-16">
        <h1 className="text-2xl md:text-2xl lg:text-3xl font-bold text-gray-950">
          More Data for Tank and Solar Water Heater
        </h1>
        <p className="mt-4 text-base md:text-md lg:text-lg text-gray-600 max-w-screen-md mx-auto">
          This section provides comprehensive information and specifications for
          both traditional storage tanks and solar water heaters. The data
          includes details on tank capacities, materials used, insulation types,
          and energy efficiency ratings.
        </p>
      </div>

      <div className="container mx-auto space-y-12">
        <div
          data-aos="fade-right"
          className="dataSheet flex flex-col overflow-hidden rounded-md shadow-xl lg:flex-row">
          <img
            src={dataSheetContent.img}
            alt="dataSheet"
            className="h-80 w-96 bg-center bg-cover bg-no-repeat p-3 bg-slate-300 hover:scale-105 hover:rounded-md"
          />
          <div className="flex flex-col justify-center flex-1 p-6 dark:bg-gray-50">
            <span className="text-lg uppercase dark:text-[--primary-color]">
              {dataSheetContent.brand}
            </span>
            <h3 className="text-3xl font-bold">{dataSheetContent.title}</h3>
            <p className="my-6 dark:text-gray-600">
              {dataSheetContent.description}
            </p>
            <a
              download
              href={dataSheetContent.downloadUrl}
              type="button"
              className="button self-start text-gray-100">
              {dataSheetContent.button}
            </a>
          </div>
        </div>

        <div
          data-aos="fade-left"
          className="detailsOfProduct flex flex-col overflow-hidden rounded-md shadow-xl lg:flex-row-reverse">
          <img
            src={detailsOfProductContent.img}
            alt="dataSheet"
            className="h-80 w-96 bg-center bg-cover bg-no-repeat p-3 bg-slate-300 hover:scale-105 hover:rounded-md"
          />
          <div className="flex flex-col justify-center flex-1 p-6 dark:bg-gray-50">
            <span className="text-lg uppercase dark:text-[--primary-color]">
              {detailsOfProductContent.brand}
            </span>
            <h3 className="text-3xl font-bold">
              {detailsOfProductContent.title}
            </h3>
            <p className="my-6 dark:text-gray-600">
              {detailsOfProductContent.description}
            </p>
            <a
              download
              href={detailsOfProductContent.downloadUrl}
              className="button self-start text-gray-100">
              {detailsOfProductContent.button}
            </a>
          </div>
        </div>

        <div
          data-aos="fade-right"
          className="controller flex flex-col overflow-hidden rounded-md shadow-xl lg:flex-row">
          <img
            src={controllerContent.img}
            alt="dataSheet"
            className="h-80 w-96 bg-center bg-cover bg-no-repeat p-3 bg-slate-300 hover:scale-105 hover:rounded-md"
          />
          <div className="flex flex-col justify-center flex-1 p-6 dark:bg-gray-50">
            <span className="text-lg uppercase dark:text-[--primary-color]">
              {controllerContent.brand}
            </span>
            <h3 className="text-3xl font-bold">{controllerContent.title}</h3>
            <p className="my-6 dark:text-gray-600">
              {controllerContent.description}
            </p>
            <a
              download
              href={controllerContent.downloadUrl}
              className="button self-start text-gray-100">
              {controllerContent.button}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DataSheet;
