import React, { useEffect } from "react";
import "./Home.css";

//* Imported Images
import mainVideo from "./videos/mainVideo.mp4";
import img5 from "../../images/5.jpg";
import vacuum from "./imgs/vacuum.avif";
import vacuum2 from "./imgs/vacuum2.avif";
import vacuum3 from "./imgs/vacuum3.jpg";
import vacuum4 from "./imgs/vacuum4.png";
import hours from "../../Assets/hourImg.png";
import powerWater from "../../Assets/powerWater.png";
import sun from "../../Assets/sun.png";
import years from "../../Assets/years.png";
import seasons from "../../Assets/seasons.png";
import quality from "../../Assets/quality.png";
import { Link } from "react-router-dom";

//* react-icons
import { AiOutlineFire } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlinePhone } from "react-icons/md";

// Aos
import Aos from "aos";
import "aos/dist/aos.css";

//* Hero Section
const heroSecContent = {
  title: "Leading Company In Solar Energy",
  description:
    "Superior technology as Stellar Solar Energy Systems and with our quality understanding, we produce our products with 1st class material.",
};

//* Our Business Section
const ourBusinessCards = [
  {
    id: 1,
    icon: "AiOutlineFire",
    title: "The no-fuss guide to upselling and cross selling",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    id: 2,
    icon: "AiOutlineFire",
    title: "The no-fuss guide to upselling and cross selling",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    id: 3,
    icon: "AiOutlineFire",
    title: "The no-fuss guide to upselling and cross selling",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    id: 4,
    icon: "AiOutlineFire",
    title: "The no-fuss guide to upselling and cross selling",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    id: 5,
    icon: "AiOutlineFire",
    title: "The no-fuss guide to upselling and cross selling",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    id: 6,
    icon: "AiOutlineFire",
    title: "The no-fuss guide to upselling and cross selling",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
];

//* About Content Section
const headingAboutContent = {
  title: "What do you need Stellar for?",
  description:
    "Choose what you want Stellar for and we’ll help you decide on thebest product.",
};
const aboutContent = {
  imgLeft: {
    img: vacuum,
    title: "Flat Heater",
  },
  threeImgsCenter: {
    one: {
      img: vacuum2,
      title: "Solar Water Heater unPressurized",
    },
    two: {
      img: vacuum3,
      title: "Our Projects",
    },
    three: {
      img: vacuum4,
      title: "Our Projects is high quality",
    },
  },
  imgRight: {
    img: img5,
    title: "Solar Water Heater unPressurized",
  },
};

//* Features Section
const titleFeatures = {
  title: "Vacuum Solar Power",
  description: "Stellar SOLAR",
};
const leftSideFeatures = [
  {
    title: "Maximum Efficiency",
    img: hours,
    description: "NO POWER CONSUMPTION",
  },
  {
    title: "Nature Friendly",
    img: powerWater,
    description: "100% SAFE",
  },
  {
    title: "Aesthetic Look",
    img: sun,
    description: "VERY HIGH WATER TEMP",
  },
];
const rightSideFeatures = [
  {
    title: "5 Year Warranty",
    img: years,
    description: "ON THE WHOLE PRODUCT",
  },
  {
    title: "4 Seasons Use",
    img: seasons,
    description: "OUR ENERGY SYSTEM ENSURE TROUBLE-FREE USE IN 4 SEASONS",
  },
  {
    title: "Quality",
    img: quality,
    description: "TANKS COLD WATER AND HOT WATER COMPLETE STAINLESS CHROME.",
  },
];

const Home = () => {
  useEffect(() => {
    Aos.init({
      duration: 1300,
    });
  }, []);

  return (
    <>
      <section className="heroSec relative">
        <div className="video relative bg-cover bg-center bg-no-repeat h-screen">
          <video
            preload="metadata"
            loop
            autoPlay
            muted
            className="w-full h-full object-cover"
            src={mainVideo}></video>
        </div>

        <div className="absolute inset-0 bg-gradient-to-b from-black/40 via-transparent to-black/70"></div>

        <div className="absolute inset-0 flex items-center justify-center px-4 py-32 sm:px-6 lg:px-8">
          <div className="text-center max-w-2xl mx-auto text-white">
            <h1
              data-aos="fade-up"
              className="text-4xl sm:text-5xl text-gray-950 md:text-6xl font-extrabold">
              {heroSecContent.title}
            </h1>

            <p
              data-aos="fade-up"
              className="mt-4 text-lg sm:text-xl md:text-2xl text-gray-900 max-w-lg mx-auto">
              {heroSecContent.description}
            </p>

            <div className="mt-8 flex flex-wrap gap-4 justify-center">
              <a
                data-aos="fade-right"
                href="https://wa.me/+201552900437"
                className="block rounded flex items-center bg-gray-950 px-12 py-3 text-lg font-medium text-white shadow hover:bg-gray-900 focus:outline-none focus:ring active:bg-rose-500 sm:w-auto">
                <FaWhatsapp className="mr-2 text-3xl" /> WhatsApp
              </a>

              <a
                data-aos="fade-left"
                href="tel:+201014790046"
                className="inline-flex items-center justify-center bg-white px-6 py-3 rounded font-[600] text-lg text-gray-950 shadow focus:outline-none focus:ring hover:bg-gray-200">
                <MdOutlinePhone className="mr-2 text-2xl" />
                <span>Phone</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section class="ourRecent pt-20 lg:pt-[120px] pb-10 lg:pb-20 w-full bg-slate-300 mt-4">
        <div class="container">
          <div data-aos="fade-up" class="flex flex-wrap justify-center -mx-4">
            <div class="w-full px-4">
              <div class="text-center mx-auto mb-[60px] lg:mb-20 max-w-[550px]">
                <span class="font-semibold text-2xl text-[--primary-color] mb-2 block">
                  Our Business
                </span>
                <h2
                  style={{ lineHeight: "1.3" }}
                  class="
                  font-bold
                  text-3xl
                  sm:text-4xl
                  md:text-[40px]
                  text-dark
                  mb-4
                  ">
                  Stellar-eg Solar Water Heater Specifications
                </h2>
                <p class="text-base text-gray-700 text-lg">
                  When you get our products, be completely confident that you
                  are on the right path, a guarantee on the entire product,
                  after-sales service, the best quality, the highest water
                  heating, and many other features...
                </p>
              </div>
            </div>
          </div>

          <div
            data-aos="fade-up"
            className="min-h-screen flex items-center justify-center">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center mx-auto">
              {ourBusinessCards.map((data, i) => {
                return (
                  <div
                    key={i}
                    className="card w-full px-4 mb-10 relative text-center">
                    <div className="max-w-[370px] mx-auto gap-4">
                      <div className="overflow-hidden mb-20 relative">
                        <AiOutlineFire className="icon text-7xl text-[#1963A6] text-center relative left-[50%] translate-x-[-50%]" />
                      </div>
                      <div className="mt-[-60px]">
                        <h3
                          className="font-semibold transition
              text-xl
              sm:text-2xl
              lg:text-xl
              xl:text-2xl
              mb-4
              inline-block
              text-dark">
                          {data.title}
                        </h3>
                        <p className="text-base text-body-color">
                          {data.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white mt-10 p-8">
        <div data-aos="fade-up" className="title text-center mb-8">
          <h1 className="text-3xl text-gray-900">
            {headingAboutContent.title}
          </h1>
          <p className="text-gray-700 text-lg">
            {headingAboutContent.description}
          </p>
        </div>

        <div class="py-4 px-2 mx-auto max-w-screen-xl sm:py-4 lg:px-6">
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 h-full">
            <div
              data-aos="fade-right"
              class="col-span-2 sm:col-span-1 md:col-span-2 bg-gray-50 h-auto md:h-full flex flex-col">
              <Link
                to="/treatments"
                class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow">
                <img
                  src={aboutContent.imgLeft.img}
                  alt="img"
                  class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-200 ease-in-out"
                />
                <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                <h3 class="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
                  {aboutContent.imgLeft.title}
                </h3>
              </Link>
            </div>

            <div
              data-aos="fade-left"
              class="col-span-2 sm:col-span-1 md:col-span-2 bg-stone-50">
              <Link
                to="/bridges"
                class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 mb-4">
                <img
                  src={aboutContent.threeImgsCenter.one.img}
                  alt="img"
                  class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-200 ease-in-out"
                />
                <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                <h3 class="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
                  {aboutContent.threeImgsCenter.one.title}
                </h3>
              </Link>
              <div class="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-2">
                <Link
                  to="/buildings"
                  class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40">
                  <img
                    src={aboutContent.threeImgsCenter.two.img}
                    alt="img"
                    class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-200 ease-in-out"
                  />
                  <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                  <h3 class="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
                    {aboutContent.threeImgsCenter.two.title}
                  </h3>
                </Link>
                <Link
                  to="/process"
                  class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40">
                  <img
                    src={aboutContent.threeImgsCenter.three.img}
                    alt="img"
                    class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-200 ease-in-out"
                  />
                  <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                  <h3 class="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
                    {aboutContent.threeImgsCenter.three.title}
                  </h3>
                </Link>
              </div>
            </div>

            <div
              data-aos="fade-left"
              class="col-span-2 sm:col-span-1 md:col-span-1 bg-sky-50 h-auto md:h-full flex flex-col">
              <Link
                to="/infrastructure"
                class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow">
                <img
                  src={aboutContent.imgRight.img}
                  alt="img"
                  class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-200 ease-in-out"
                />
                <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                <h3 class="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
                  {aboutContent.imgRight.title}
                </h3>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="mt-12 mb-12">
        <div className="container mx-auto px-4">
          <div data-aos="fade-up" className="text-center">
            <h1 className="text-4xl font-bold mb-4">{titleFeatures.title}</h1>
            <p className="text-lg">{titleFeatures.description}</p>
          </div>
          <div className="flex flex-wrap justify-center mt-8">
            <div className="w-full lg:w-1/3 mb-8 lg:mb-0">
              <div className="space-y-8">
                {leftSideFeatures.map((data, i) => {
                  return (
                    <div
                      key={i}
                      data-aos="fade-right"
                      className="flex items-center">
                      <img
                        src={data.img}
                        alt="Stellar solar"
                        className="w-16 h-16 mr-4"
                      />
                      <div>
                        <h4 className="text-xl font-semibold">
                          <h2>{data.title}</h2>
                        </h4>
                        <p>{data.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="w-full relative right-5 lg:w-1/3 mb-8 lg:mb-0 flex justify-center p-2 bg-slate-300 shadow rounded-sm">
              <img
                className="w-full lg:w-auto h-auto  hover:scale-105 hover:rounded-md"
                src={vacuum3}
                alt="inci solar enerji"
              />
            </div>

            <div className="w-full lg:w-1/3 mb-8 lg:mb-0">
              <div className="space-y-8 ml-2">
                {rightSideFeatures.map((data, i) => {
                  return (
                    <div
                      key={i}
                      data-aos="fade-left"
                      className="flex items-center">
                      <div className="mr-4">
                        <h4 className="text-xl font-semibold">
                          <h2>{data.title}</h2>
                        </h4>
                        <p>{data.description}</p>
                      </div>
                      <img src={data.img} alt="title" className="w-16 h-16" />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
