import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "./tailwind.config";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import httpApi from "i18next-http-backend";
import cookies from "js-cookie"
// import dotenv from "dotenv";

// Imported Pages
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "./Pages/Home/Home";
import AboutUs from "./Pages/AboutUs/AboutUs";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Products from "./Pages/Products/Products";
import Product from "./Pages/Product/Product";
import Terms from "./Pages/Terms/Terms";
import Prevacy from "./Pages/Prevacy/Prevacy";
import FloatingBtn from "./Components/floating-button/FloatingBtn";
import DataSheet from "./Data_sheet/DataSheet";
import { useEffect, useState } from "react";

// i18next.use(httpApi).init(i18nextOptions);

//* React-i18next Library functions
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(httpApi) // passes i18n down to react-i18next
  .init({
    lng: "en",
    fallbackLng: "en",
    detection: {
      order: [
        "cookie",
        "htmlTag",
        "querystring",
        "localStorage",
        "navigator",
        "path",
        "subdomain",
        "sessionStorage",
      ],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/locale/{{lng}}/translation.json",
    },
  });

function App() {
  // dotenv.config();
  const { t } = useTranslation();
  const lang = cookies.get("i18next") || "en";

  useEffect(() => {
    window.document.dir = i18n.dir();
  }, [lang]);

  // State for header collapse
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };

    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  return (
    <div className="App">
      {/* <h1>{t("Hello World")}</h1>
      <button
        onClick={() => {
          i18n.changeLanguage("ar");
        }}>
        ar
      </button>
      <br />
      <button
        onClick={() => {
          i18n.changeLanguage("en");
        }}>
        en
      </button> */}
      {isLoading ? (
        <div class="flex justify-center items-center min-h-screen over">
          <div class="cont relative mx-auto max-w-full">
            <div class="cloud front">
              <span class="left-front"></span>
              <span class="right-front"></span>
            </div>
            <span class="sun sunshine"></span>
            <span class="sun"></span>
            <div class="cloud back">
              <span class="left-back"></span>
              <span class="right-back"></span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/products" element={<Products />} />
            <Route path="/product" element={<Product />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/prevacy" element={<Prevacy />} />
            <Route path="/dataSheet" element={<DataSheet />} />
          </Routes>
          <Footer />
          <FloatingBtn />
        </>
      )}
    </div>
  );
}

export default App;
